import React from "react"
import { LegalInfoContainer } from "../Layout/GlobalStyle"

export default function UserAgreement() {
  return (
    <LegalInfoContainer>
      <div className="agreement-columns-grid">
        <div className="agreement-column">
          <div>
            <h1>Terms and conditions</h1>
            <p>
              These terms and conditions ("Agreement") set forth the general
              terms and conditions of your use of the "FindAnHerb" mobile
              application ("Mobile Application" or "Service") and any of its
              related products and services (collectively, "Services"). This
              Agreement is legally binding between you ("User", "you" or "your")
              and this Mobile Application developer ("Operator", "we", "us" or
              "our"). By accessing and using the Mobile Application and
              Services, you acknowledge that you have read, understood, and
              agree to be bound by the terms of this Agreement. If you are
              entering into this Agreement on behalf of a business or other
              legal entity, you represent that you have the authority to bind
              such entity to this Agreement, in which case the terms "User",
              "you" or "your" shall refer to such entity. If you do not have
              such authority, or if you do not agree with the terms of this
              Agreement, you must not accept this Agreement and may not access
              and use the Mobile Application and Services. You acknowledge that
              this Agreement is a contract between you and the Operator, even
              though it is electronic and is not physically signed by you, and
              it governs your use of the Mobile Application and Services.
            </p>

            <h1>Accounts and membership</h1>
            <p>
              If you create an account in the Mobile Application, you are
              responsible for maintaining the security of your account and you
              are fully responsible for all activities that occur under the
              account and any other actions taken in connection with it. We may,
              but have no obligationto, monitor and review new accounts before
              you may sign in and start using the Services. Providing false
              contact information of any kind may result in the termination of
              your account. You must immediately notify us of any unauthorized
              uses of your accountor any other breaches of security. We will not
              be liable for any acts or omissions by you, including any damages
              of any kind incurred as a result of such acts or omissions
            </p>

            <h1>User Content</h1>
            <p>
              We do not own any data, information or material (collectively,
              "Content") that you submit in the Mobile Application in the course
              of using the Service. You shall have sole responsibility for the
              accuracy, quality, integrity, legality, reliability,
              appropriateness, and intellectual property ownership or right to
              use of all submitted Content. We may monitor and review the
              Content in the Mobile Application submitted or created using our
              Services by you. You grant us permission to access, copy,
              distribute, store, transmit, reformat, display and perform the
              Content of your user account solely as required for the purpose of
              providing the Services to you. Without limiting any of those
              representations or warranties, we have the right, though not the
              obligation, to, in our own sole discretion, refuse or remove any
              Content that, in ourreasonable opinion, violates any of our
              policies or is in any way harmful or objectionable. You also grant
              us the license to use, reproduce, adapt, modify, publish or
              distribute the Content created by you or stored in your user
              account for commercial, marketing or any similar purpose.
            </p>

            <h1>Accuracy of informationand Disclaimer</h1>
            <p>
              Occasionally there may be information in the Mobile Application
              that contains typographical errors, inaccuracies or omissions that
              may relate to product descriptions, pricing, availability,
              promotions and offers. We reserve the right to correct any
              errors,inaccuracies or omissions, and to change or update
              information if any information in the Mobile Application or
              Services is inaccurate at any time without prior notice. We
              undertake no obligation to update, amend or clarify information in
              the Mobile Application including, without limitation, pricing
              information, except as required by law. No specified update or
              refresh date applied in the Mobile Application should be taken to
              indicate that all information in the Mobile Application or
              Services has been modified or updated.FindAnHerb does not provide
              any professional medical advice, treatments or information. We are
              not professionals. The information provided in the app is only
              based on our own research from different medical and non-medical
              sources. None of the information provided has been reviewed by
              professionals or experts. If you decide to use any of the remedies
              listed in the app, you are using them at your own risk. We
              disclaim any liability for the decisions you make. Before using
              any remedies listed in our app, or making any decisions based on
              our information we suggest you seek professional medical advice a
              physician or professional medical healthcare provider first. In
              using our app and signing this agreement you also agree that
              FindAnHerb, the developer and operators of this app will not be
              liable or otherwise responsible for any decisions you make or do
              not make or actions you take based on the information in our app
            </p>

            <h1>Third Party services</h1>
            <p>
              If you decide to enable, access or use third party services, be
              advised that your access and use of such other services are
              governed solely by the terms and conditions of such other
              services, and we do not endorse, are not responsible or liable
              for, and make no representations as to any aspect of such other
              services, including, without limitation, their content or the
              manner in which they handle data (including your data) or any
              interaction between you and the provider of such other services.
              You irrevocably waive any claim against the Operator with
              respectto such other services. The Operator is not liable for any
              damage or loss caused or alleged to be caused by or in connection
              with your enablement, access or use of any such other services, or
              your reliance on the privacy practices, data security processesor
              other policies of such other services. You may be required to
              register for or log into such other services on their respective
              platforms. By enabling any other services, you are expressly
              permitting the Operator to disclose your data as necessary to
              facilitate the use or enablement of such other service
            </p>

            <h1>Backups</h1>
            <p>
              We are not responsible for the Content residing in the Mobile
              Application. In no event shall we be held liable for any loss of
              any Content. It is your sole responsibility to maintain
              appropriatebackup of your Content. Notwithstanding the foregoing,
              on some occasions and in certain circumstances, with absolutely no
              obligation, we may be able to restore some or all of your data
              that has been deleted as of a certain date and time when we may
              have backed up data for our own purposes. We make no guarantee
              that the data you need will be available.
            </p>

            <h1>Links to other resources</h1>
            <p>
              Although the Mobile Application and Services may link to other
              resources (such as websites, mobile applications, etc.), we are
              not, directly or indirectly, implying any approval, association,
              sponsorship, endorsement, or affiliation with any linked resource,
              unless specifically stated herein. Some of the links in the Mobile
              Application may be "affiliate links". This means if you click on
              the link and purchase an item, the Operator will receive an
              affiliate commission. We are not responsible for examining or
              evaluating, and we do not warrant the offerings of, any businesses
              or individuals or the content of their resources. We do not assume
              any responsibility or liability for the actions, products,
              services, and content of any other third parties. You should
              carefully review the legal statements and other conditions of use
              of any resource which you access through a link in the Mobile
              Application and Services. Your linking to any other off-site
              resources is at your own risk
            </p>
            <h1>Disclaimer and warranty</h1>
            <p>
              You agree that such Service is provided on an "as is" and "as
              available" basis and that your use of the Mobile Application and
              Services is solely at your own risk. We expressly disclaim all
              warranties of any kind, whether express or implied, including but
              not limited to the implied warranties of merchantability, fitness
              for a particular purpose and non-infringement. We make no warranty
              that the Services will meet your requirements, or that the Service
              will be uninterrupted, timely, secure, or error-free; nor do we
              make any warranty as to the results that may be obtained from the
              use of the Service or as to the accuracy or reliability of any
              information obtained through the Service or that defects in the
              Service will be corrected. You understand and agree that any
              material and/or data downloaded or otherwise obtained through the
              use of Service is done at your own discretion and risk and that
              you will be solely responsible for any damage or loss of data that
              results from the download of such material and/or data. We make no
              warranty regarding any goods or services purchased or obtained
              through the Service or any transactions entered into through the
              Service unless stated otherwise. No advice or information, whether
              oral or written, obtained by you from us or through the Service
              shall create any warranty not expressly made herein
            </p>

            <h1>Severability</h1>

            <p>
              All rights and restrictions contained in this Agreement may be
              exercised and shall be applicable and binding only to the extent
              that they do not violate any applicable laws and are intended to
              be limited to the extent necessary so that they will not render
              this Agreement illegal, invalid or unenforceable. If any provision
              or portion of any provision of this Agreement shall be held to be
              illegal, invalid or unenforceable by a court of competent
              jurisdiction, it is the intention of the parties that the
              remaining provisions or portions thereof shall constitute their
              agreement with respect to the subject matter hereof, and all such
              remaining provisions or portions thereof shall remain in full
              force and effect
            </p>

            <h1>Changes and amendments</h1>
            <p>
              We reserve the right to modify this Agreement or its terms
              relating to the Mobile Application and Services at any time,
              effective upon posting of an updated version of this Agreement in
              the Mobile Application. When we do, we will post a notification in
              the Mobile Application. Continued use of the Mobile Application
              and Services after any such changes shall constitute your consent
              to such changes
            </p>

            <h1>Acceptance of these terms</h1>

            <p>
              You acknowledge that you have read this Agreement and agree to all
              its terms and conditions. By accessing and using the Mobile
              Application and Services you agree to be bound by this Agreement.
              If you do not agree to abide by the terms of this Agreement, you
              are not authorized to access or use the Mobile Application and
              Services
            </p>

            <h1>Contacting us</h1>
            <p>
              If you have any questions about this Agreement, please contact us.
            </p>
          </div>
        </div>
      </div>
    </LegalInfoContainer>
  )
}
