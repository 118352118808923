import React from 'react'
import Layout from "../components/Layout"
import UserAgreement from '../components/UserAgreement'





const UserAgreementPage = () => (
  <Layout seoProps={{title: "User Agreement"}}>
    <UserAgreement />
  </Layout>
)

export default UserAgreementPage